* {
    margin: 2px;
    padding: 2px;
    border: 2px solid red;
}

.photoBooth {
    justify-self: center;
    display: grid;
    grid-template-columns: repeat(50, 1fr);
    grid-template-rows: repeat(50, 1fr);
    height: calc(48vw * 0.69);
    margin: 1vw auto 0 auto;
    border-radius: 1vw;
    width: 48vw;
}
.photoBoothBackground{
    height: 100%;
    width: 100%;
    z-index: 1;
    grid-area: 1 / 1 / 51 / 51;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
video{
    transform: scale(1.5) rotateY(180deg) !important;
    height: 100%;
    width: 100%;
    overflow: hidden;
}